:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 240, 240, 240;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  font-family: "Pretendard Variable";
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

.head1_b {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
}
.head1_sb {
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
}

.head0 {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

.head0_sb {
  font-weight: 400;
  font-size: 28px;
  line-height: 48px;
}

.newHead {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
}

.newTitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
}

.head2_sb {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.head2_b {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.head2_m {
  font-weight: 500;

  font-size: 24px;
  line-height: 32px;
}

.subtitle1_m {
  font-weight: 500;

  font-size: 21px;
  line-height: 30px;
}

.subtitle1_sb {
  font-weight: 600;

  font-size: 21px;
  line-height: 30px;
}

.subtitle1_b {
  font-weight: 700;

  font-size: 21px;
  line-height: 30px;
}

.subtitle2_sb {
  font-weight: 600;

  font-size: 18px;
  line-height: 26px;
}

.subtitle2_b {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

.subtitle3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

.subtitle4 {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.body1 {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.body2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.body3_m {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.body3_r {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.button {
  font-weight: 600;

  font-size: 16px;
  line-height: 24px;
}

.button2 {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}

.caption1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.head_42_eb {
  font-size: 42px;
  font-weight: 800;
  line-height: 60px;
}

.head_42_b {
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
}

.head_42_sb {
  font-size: 42px;
  font-weight: 600;
  line-height: 60px;
}

.head_42_m {
  font-size: 42px;
  font-weight: 500;
  line-height: 60px;
}

.head_42_r {
  font-size: 42px;
  font-weight: 400;
  line-height: 60px;
}

.head_42_l {
  font-size: 42px;
  font-weight: 300;
  line-height: 60px;
}

.head_32_eb {
  font-size: 32px;
  font-weight: 800;
  line-height: 42px;
}

.head_32_b {
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
}

.head_32_sb {
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
}

.head_32_m {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
}

.head_32_r {
  font-size: 32px;
  font-weight: 400;
  line-height: 42px;
}

.head_32_l {
  font-size: 32px;
  font-weight: 300;
  line-height: 42px;
}

.head_28_eb {
  font-size: 28px;
  font-weight: 800;
  line-height: 40px;
}

.head_28_b {
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
}

.head_28_sb {
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
}

.head_28_m {
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
}

.head_28_r {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}

.head_28_l {
  font-size: 28px;
  font-weight: 300;
  line-height: 40px;
}

.head_24_eb {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
}

.head_24_b {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.head_24_sb {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.head_24_m {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.head_24_r {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

.head_24_l {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
}

.subtitle_21_eb {
  font-size: 21px;
  font-weight: 800;
  line-height: 30px;
}

.subtitle_21_b {
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
}

.subtitle_21_sb {
  font-size: 21px;
  font-weight: 600;
  line-height: 30px;
}

.subtitle_21_m {
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
}

.subtitle_21_r {
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
}

.subtitle_21_l {
  font-size: 21px;
  font-weight: 300;
  line-height: 30px;
}

.subtitle_18_eb {
  font-size: 18px;
  font-weight: 800;
  line-height: 26px;
}

.subtitle_18_b {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.subtitle_18_sb {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.subtitle_16_eb {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
}

.subtitle_16_b {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.subtitle_16_sb {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.subtitle_14_eb {
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
}

.subtitle_14_b {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}

.subtitle_14_sb {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.body_18_m {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.body_18_r {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.body_18_l {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

.body_16_m {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.body_16_r {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.body_16_l {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

.body_14_m {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.body_14_r {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.body_14_l {
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

.caption_12_b {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.caption_12_sb {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.caption_12_m {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.caption_12_r {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.caption_11_b {
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
}

.caption_11_sb {
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
}

.caption_11_m {
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}

.caption_11_r {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.button_16_sb {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.button_14_sb {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.button_12_sb {
  font-size: 14px;
  font-weight: 600;
  line-height: 12px;
}

.black {
  color: #000000;
}
.white {
  color: #fefeff;
}
.goreen {
  color: #2b8a3e;
}
.goreen200 {
  color: #8ce99a;
}

.yellow {
  color: #ffed4b;
}
.kakao {
  color: #fee500;
}
.kakaoText {
  color: #191919;
}
.reviewYellow {
  color: #ffc700;
}

.gray50 {
  color: #f5f5f5;
}
.gray100 {
  color: #e9e9e9;
}
.gray200 {
  color: #dedede;
}
.gray300 {
  color: #cccccc;
}
.gray400 {
  color: #b9b9b9;
}
.gray500 {
  color: #8f8f8f;
}
.gray600 {
  color: #686868;
}
.gray700 {
  color: #535353;
}
.gray800 {
  color: #3d3d3d;
}
.gray900 {
  color: #252525;
}

.green50 {
  color: #ebfbee;
}
.green100 {
  color: #d3f9d8;
}
.green150 {
  color: #b2f2bb;
}
.green200 {
  color: #8ce99a;
}
.green250 {
  color: #69db7c;
}
.green300 {
  color: #51cf66;
}
.green350 {
  color: #40c057;
}
.green400 {
  color: #37b24d;
}
.green450 {
  color: #2f9e44;
}
.green500 {
  color: #2b8a3e;
}

.warmGray50 {
  color: #fafafa;
}
.warmGray100 {
  color: #f6f4f4;
}
.warmGray150 {
  color: #f1efef;
}
.warmGray200 {
  color: #edeae9;
}
.warmGray250 {
  color: #e8e5e4;
}
.warmGray300 {
  color: #e3dfdf;
}
.warmGray350 {
  color: #dfdad9;
}
.warmGray400 {
  color: #dad5d4;
}
.warmGray450 {
  color: #d6cfce;
}
.warmGray500 {
  color: #d1cac9;
}
.warmGray550 {
  color: #beb4b3;
}
.warmGray600 {
  color: #ab9f9d;
}
.warmGray650 {
  color: #988987;
}
.warmGray700 {
  color: #857471;
}
.warmGray750 {
  color: #6f605e;
}
.warmGray800 {
  color: #594d4b;
}
.warmGray850 {
  color: #423a39;
}
.warmGray900 {
  color: #2c2726;
}
.warmGray950 {
  color: #171313;
}

.brown50 {
  color: #f5eeea;
}
.brown100 {
  color: #ebdcd6;
}
.brown150 {
  color: #e1cbc1;
}
.brown200 {
  color: #d7b9ac;
}
.brown250 {
  color: #cda897;
}
.brown300 {
  color: #c39783;
}
.brown350 {
  color: #b9856e;
}
.brown400 {
  color: #af7459;
}
.brown450 {
  color: #9e654c;
}
.brown500 {
  color: #895842;
}
.brown550 {
  color: #7b4f3b;
}
.brown600 {
  color: #6e4635;
}
.brown650 {
  color: #603e2e;
}
.brown700 {
  color: #523528;
}
.brown750 {
  color: #452c21;
}
.brown800 {
  color: #37231a;
}
.brown850 {
  color: #291a14;
}
.brown900 {
  color: #1b120d;
}
.brown950 {
  color: #0e0907;
}

.blue50 {
  color: #f8fafd;
}
.blue100 {
  color: #f1f5fb;
}
.blue150 {
  color: #eaeff8;
}
.blue200 {
  color: #e3eaf6;
}
.blue250 {
  color: #dbe5f4;
}
.blue300 {
  color: #d4e0f2;
}
.blue350 {
  color: #cddbf0;
}
.blue400 {
  color: #c6d5ed;
}
.blue450 {
  color: #bfd0eb;
}
.blue500 {
  color: #b8cbe9;
}
.blue550 {
  color: #98b4df;
}
.blue600 {
  color: #789cd5;
}
.blue650 {
  color: #5885cb;
}
.blue700 {
  color: #3b6ebf;
}
.blue750 {
  color: #315c9f;
}
.blue800 {
  color: #274a7f;
}
.blue850 {
  color: #1e3760;
}
.blue900 {
  color: #142540;
}
.blue950 {
  color: #0a1220;
}

.orange50 {
  color: #fefaf6;
}
.orange100 {
  color: #fef5ee;
}
.orange150 {
  color: #fdf1e5;
}
.orange200 {
  color: #fdecdd;
}
.orange250 {
  color: #fce7d4;
}
.orange300 {
  color: #fbe2cb;
}
.orange350 {
  color: #fbddc3;
}
.orange400 {
  color: #fad9ba;
}
.orange450 {
  color: #fad4b2;
}
.orange500 {
  color: #f9cfa9;
}
.orange550 {
  color: #f6b982;
}
.orange600 {
  color: #f4a35b;
}
.orange650 {
  color: #f18334;
}
.orange700 {
  color: #ea7810;
}
.orange750 {
  color: #c3640e;
}
.orange800 {
  color: #9c500b;
}
.orange850 {
  color: #753c08;
}
.orange900 {
  color: #4e2805;
}
.orange950 {
  color: #271403;
}
